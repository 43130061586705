@import '../../style/themes/index';
@import '../../style/mixins/index';

@upload-prefix-cls: ~'@{ant-prefix}-upload';
@upload-item: ~'@{ant-prefix}-upload-list-item';

.@{upload-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &&-select-picture-card {
    .@{upload-prefix-cls}-rtl& {
      float: right;
      margin-right: 0;
      margin-left: 8px;
    }
  }
}

.@{upload-prefix-cls}-list {
  &-rtl {
    direction: rtl;
  }
  &-item-list-type-text {
    &:hover {
      .@{upload-prefix-cls}-list-item-name-icon-count-1 {
        .@{upload-prefix-cls}-list-rtl & {
          padding-right: 22px;
          padding-left: 14px;
        }
      }
      .@{upload-prefix-cls}-list-item-name-icon-count-2 {
        .@{upload-prefix-cls}-list-rtl & {
          padding-right: 22px;
          padding-left: 28px;
        }
      }
    }
  }
  &-item {
    &-name {
      .@{upload-prefix-cls}-list-rtl & {
        padding-right: @font-size-base + 8px;
        padding-left: 0;
      }
    }

    &-name-icon-count-1 {
      .@{upload-prefix-cls}-list-rtl & {
        padding-left: 14px;
      }
    }

    &-card-actions {
      .@{upload-prefix-cls}-list-rtl & {
        right: auto;
        left: 0;
      }
      .@{iconfont-css-prefix} {
        .@{upload-prefix-cls}-list-rtl & {
          padding-right: 0;
          padding-left: 5px;
        }
      }
    }

    &-info {
      .@{upload-prefix-cls}-list-rtl & {
        padding: 0 4px 0 12px;
      }
    }

    .@{iconfont-css-prefix}-close {
      .@{upload-prefix-cls}-list-rtl & {
        right: auto;
        left: 4px;
      }
    }

    &-error &-card-actions {
      .@{iconfont-css-prefix} {
        .@{upload-prefix-cls}-list-rtl & {
          padding-right: 0;
          padding-left: 5px;
        }
      }
    }

    &-progress {
      .@{upload-prefix-cls}-list-rtl & {
        padding-right: @font-size-base + 12px;
        padding-left: 0;
      }
    }
  }

  &-picture,
  &-picture-card {
    .@{upload-item}-thumbnail {
      .@{upload-prefix-cls}-list-rtl& {
        right: 8px;
        left: auto;
      }
    }

    .@{upload-item}-icon {
      .@{upload-prefix-cls}-list-rtl& {
        right: 50%;
        left: auto;
        transform: translate(50%, -50%);
      }
    }

    .@{upload-item}-name {
      .@{upload-prefix-cls}-list-rtl& {
        margin: 0 8px 0 0;
        padding-right: 48px;
        padding-left: 8px;
      }
    }

    .@{upload-item}-name-icon-count-1 {
      .@{upload-prefix-cls}-list-rtl& {
        padding-right: 48px;
        padding-left: 18px;
      }
    }

    .@{upload-item}-name-icon-count-2 {
      .@{upload-prefix-cls}-list-rtl& {
        padding-right: 48px;
        padding-left: 36px;
      }
    }

    .@{upload-item}-progress {
      .@{upload-prefix-cls}-list-rtl& {
        padding-right: 56px;
        padding-left: 0;
      }
    }

    .@{iconfont-css-prefix}-close {
      .@{upload-prefix-cls}-list-rtl& {
        right: auto;
        left: 8px;
      }
    }
  }

  &-picture-card {
    &-container {
      .@{upload-prefix-cls}-list-rtl & {
        float: right;
        margin: 0 0 8px 8px;
      }
    }

    .@{upload-item} {
      .@{upload-prefix-cls}-list-rtl& {
        float: right;
        margin: 0 0 8px 8px;
      }
    }

    .@{upload-item}-actions {
      .@{upload-prefix-cls}-list-rtl& {
        right: 50%;
        left: auto;
        transform: translate(50%, -50%);
      }
    }

    .@{upload-item}-file + .@{upload-item}-name {
      .@{upload-prefix-cls}-list-rtl& {
        margin: 8px 0 0;
        padding: 0;
      }
    }

    .@{upload-item}-info {
      .@{upload-prefix-cls}-list-rtl& {
        padding: 0;
      }
    }
  }
}
