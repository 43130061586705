@import '../../style/themes/index';
@import '../../style/mixins/index';

@statistic-prefix-cls: ~'@{ant-prefix}-statistic';

.@{statistic-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-content {
    &-prefix {
      .@{statistic-prefix-cls}-rtl & {
        margin-right: 0;
        margin-left: 4px;
      }
    }

    &-suffix {
      .@{statistic-prefix-cls}-rtl & {
        margin-right: 4px;
        margin-left: 0;
      }
    }
  }
}
