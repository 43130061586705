@import 'box';

@dialog-mask-modal-prefix-cls: ~'@{ant-prefix}-modal';
@dialog-mask-image-prefix-cls: ~'@{ant-prefix}-image-preview';

.modal-mask() {
  .@{dialog-mask-modal-prefix-cls},
  .@{dialog-mask-image-prefix-cls} {
    pointer-events: none;
  }

  .@{dialog-mask-modal-prefix-cls}.zoom-enter,
  .@{dialog-mask-modal-prefix-cls}.zoom-appear,
  .@{dialog-mask-image-prefix-cls}.zoom-enter,
  .@{dialog-mask-image-prefix-cls}.zoom-appear {
    transform: none; // reset scale avoid mousePosition bug
    opacity: 0;
    animation-duration: @animation-duration-slow;
    user-select: none; // https://github.com/ant-design/ant-design/issues/11777
  }

  .@{dialog-mask-modal-prefix-cls}-mask,
  .@{dialog-mask-image-prefix-cls}-mask {
    .box(fixed);
    z-index: @zindex-modal-mask;
    height: 100%;
    background-color: @modal-mask-bg;
    filter: ~'alpha(opacity=50)';

    &-hidden {
      display: none;
    }
  }

  .@{dialog-mask-modal-prefix-cls}-wrap,
  .@{dialog-mask-image-prefix-cls}-wrap {
    .box(fixed);
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
  }
}

.modal-mask();
