@import '../../style/themes/index';
@import '../../style/mixins/index';

@breadcrumb-prefix-cls: ~'@{ant-prefix}-breadcrumb';

.@{breadcrumb-prefix-cls} {
  &-rtl {
    .clearfix;
    direction: rtl;

    > span {
      float: right;
    }
  }

  &-link {
    > .@{iconfont-css-prefix} + span,
    > .@{iconfont-css-prefix} + a {
      .@{breadcrumb-prefix-cls}-rtl & {
        margin-right: 4px;
        margin-left: 0;
      }
    }
  }

  &-overlay-link {
    > .@{iconfont-css-prefix} {
      .@{breadcrumb-prefix-cls}-rtl & {
        margin-right: 4px;
        margin-left: 0;
      }
    }
  }
}
